import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { currentBrand } from '../../../config'
import { getPath } from '../../../routing/get-path'
import img640 from '../../../assets/images/landing/people-in-grass/640x400.webp'
import img768 from '../../../assets/images/landing/people-in-grass/768x480.webp'
import img1024 from '../../../assets/images/landing/people-in-grass/1024x640.webp'
import img1280 from '../../../assets/images/landing/people-in-grass/1280x800.webp'
import img1920 from '../../../assets/images/landing/people-in-grass/1920x1200.webp'
import img2560 from '../../../assets/images/landing/people-in-grass/2560x1600.webp'
import { LinkButtonV2 } from '../../../ui-shared/_core/link-button-v2'

import { ResponsiveImage } from './responsive-image'

const Wrapper = styled.div(({ theme }) => ({
  position: 'relative',
  background: theme.colors.core.brown,
  aspectRatio: '1 / 1',
  [theme.mediaQueries.lgUp]: {
    height: 'clamp(480px, 80vh, 1024px)',
    aspectRatio: 'initial',
  },
}))

const Content = styled.div(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  padding: `${theme.spacing['12x']} ${theme.spacing['6x']}`,
  maxWidth: 1200,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  height: '100%',
  gap: theme.spacing['12x'],
  [theme.mediaQueries.lgUp]: {
    padding: `${theme.spacing['24x']} 10%`,
    justifyContent: 'flex-start',
  },
}))

const Img = styled(ResponsiveImage)({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
})

const Title = styled.h2(({ theme }) => ({
  ...theme.typography.display.xs,
  color: 'white',
  whiteSpace: 'pre-line',
  // Doesn't work in most browsers but hey, progressive enhancement!
  textWrap: 'pretty',
  [theme.mediaQueries.mdUp]: theme.typography.display.xl,
  [theme.mediaQueries.lgUp]: theme.typography.display['3xl'],
}))

export function CTA() {
  const { t } = useTranslation('cta')
  const translationOptions = { context: currentBrand }

  const route = currentBrand === 'qasa_france' ? getPath('help') : '/find-home'
  return (
    <Wrapper>
      <Img
        src={img640}
        alt=""
        srcSet={{
          640: img640,
          768: img768,
          1024: img1024,
          1280: img1280,
          1920: img1920,
          2560: img2560,
        }}
      />
      <Content>
        <Title>{t('heading', translationOptions)}</Title>
        <LinkButtonV2 href={route} size="lg" variant="primary">
          {t('cta', { context: currentBrand })}
        </LinkButtonV2>
      </Content>
    </Wrapper>
  )
}
