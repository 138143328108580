import styled from '@emotion/styled'
import { Stack } from '@qasa/ui'
import { useTranslation } from 'react-i18next'
import { pxToRem } from '@qasa/qds-ui'

import { HuggableCard } from '../huggable-card'

const Wrapper = styled.section(({ theme }) => ({
  backgroundColor: theme.colors.core.softPink,
  padding: `${theme.spacing['12x']} ${theme.spacing['6x']}`,
  [theme.mediaQueries.mdUp]: {
    padding: `${theme.spacing['24x']} 10%`,
  },
}))

const Title = styled.h2(({ theme }) => ({
  ...theme.typography.display.xs,
}))

const Description = styled.p(({ theme }) => ({
  ...theme.typography.body.xl,
  fontSize: pxToRem(32),
  lineHeight: pxToRem(36),
  // Currently isn't supported in a lot of browsers...
  // but hey, progressive enhancement!
  textWrap: 'pretty',
}))

const CardsContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing['6x'],
  [theme.mediaQueries.xlUp]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}))

type Item = {
  title: string
  description: string
}

export function HowItWorks() {
  const { t } = useTranslation('landing_how_it_works')

  const items = t('items', { returnObjects: true }) as Item[]

  return (
    <Wrapper>
      <Stack
        gap="8x"
        style={{
          // TODO: Create shared component for this
          maxWidth: 1200,
          margin: '0 auto',
        }}
      >
        <Title>{t('title')}</Title>
        <CardsContainer>
          {items.map(({ title, description }, index) => (
            <HuggableCard key={index}>
              <HuggableCard.Header>
                <HuggableCard.Title>{title}</HuggableCard.Title>
              </HuggableCard.Header>
              <HuggableCard.Divider />
              <HuggableCard.Body>
                <Description>{description}</Description>
              </HuggableCard.Body>
            </HuggableCard>
          ))}
        </CardsContainer>
      </Stack>
    </Wrapper>
  )
}
