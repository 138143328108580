import { Places } from '../../../ui-page-modules/landing/places'
import { Hero } from '../components/hero/hero'
import { Questions } from '../components/questions/questions'
import { Stats } from '../components/stats'
import { PopContent } from '../components/pop-content'
import { ListOfSEOLinks } from '../components/list-of-seo-links'
import { Testimonial } from '../components/testimonial'
import { CTA } from '../components/cta'
import { PressRoomSection } from '../components/press-room-section'
import { WelcomeHomeCard } from '../components/welcome-home-card'
import { UspsGrid } from '../components/usps-grid'

// eslint-disable-next-line import/no-default-export
export default function LandingPageContent() {
  return (
    <>
      <Hero />
      <Places />
      <UspsGrid />
      <PopContent />
      <Stats />
      <Testimonial />
      <PressRoomSection />
      <Questions />
      <CTA />
      <WelcomeHomeCard />
      <ListOfSEOLinks />
    </>
  )
}
