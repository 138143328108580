import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Heading, Paragraph, Stack } from '@qasa/qds-ui'

import { Image } from '../../ui-shared/_core/image'
import blocketLandlordImage from '../../assets/illustrations/blocket/no-saved-ads.svg'
import blocketTenantImage from '../../assets/illustrations/blocket/monitoring.svg'
import qasaLandlordImage from '../../assets/images/landing_landlord.png'
import qasaTenantImage from '../../assets/images/landing_tenant.png'
import { currentBrand } from '../../config'
import { BRAND_CONFIG } from '../../config/brand-configurations'
import type { BrandType } from '../../config/index.types'
import { getPath } from '../../routing/get-path'
import { LinkButtonV2 } from '../../ui-shared/_core/link-button-v2'

import { ExpandableUsp } from './expandable-usp'

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'start',
  overflow: 'visible',
})
const ImageWrapper = styled.div(({ theme }) => ({
  minWidth: 300,
  width: 300,
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 32,
  [theme.mediaQueries.mdUp]: {
    display: 'flex',
  },
}))

const Info = styled(Stack)({ maxWidth: 700 })

type BrandUsps = Record<
  BrandType,
  {
    landlord: { id: string }[]
    tenant: { id: string }[]
  }
>
const USPS: BrandUsps = {
  qasa: {
    landlord: [{ id: 'rent_on_time' }, { id: 'insurance' }, { id: 'rent_guarantee' }],
    tenant: [{ id: 'safety' }, { id: 'deposit' }, { id: 'support' }],
  },
  blocket: {
    landlord: [{ id: 'rent_on_time' }, { id: 'insurance' }, { id: 'rent_guarantee' }],
    tenant: [{ id: 'safety' }, { id: 'deposit' }, { id: 'support' }],
  },
  qasa_finland: {
    landlord: [{ id: 'rent_on_time' }, { id: 'insurance' }, { id: 'rent_guarantee' }],
    tenant: [{ id: 'safety' }, { id: 'deposit' }, { id: 'support' }],
  },
  // TODO: Add usps for qasa_france
  qasa_france: {
    landlord: [{ id: 'rent_on_time' }, { id: 'insurance' }, { id: 'rent_guarantee' }],
    tenant: [{ id: 'safety' }, { id: 'deposit' }, { id: 'support' }],
  },
  dotcom: {
    landlord: [{ id: 'rent_on_time' }, { id: 'insurance' }, { id: 'rent_guarantee' }],
    tenant: [{ id: 'safety' }, { id: 'deposit' }, { id: 'support' }],
  },
}

const IMAGES = {
  blocket: {
    landlord: {
      img: blocketLandlordImage,
      altI18nKey: 'image_alt_tags:illustrations.blocket.no_saved_ads',
    },
    tenant: { img: blocketTenantImage, altI18nKey: 'image_alt_tags:illustrations.blocket.monitoring' },
  },
  qasa: {
    landlord: { img: qasaLandlordImage, altI18nKey: 'image_alt_tags:images.landing_landlord' },
    tenant: { img: qasaTenantImage, altI18nKey: 'image_alt_tags:images.landing_tenant' },
  },
}
type RoleSpecificUspsProps = {
  userRole: 'tenant' | 'landlord'
}
export function RoleSpecificUsps({ userRole }: RoleSpecificUspsProps) {
  const { t } = useTranslation('role_specific_usps')

  const brandKey = currentBrand === 'blocket' ? 'blocket' : 'qasa'
  const { img, altI18nKey } = IMAGES[brandKey][userRole]
  const linkProps = { href: userRole === 'landlord' ? getPath('createListing') : '/find-home' }
  return (
    <Wrapper id={`${userRole}-usps`}>
      <ImageWrapper>
        <Image src={img} alt={t(altI18nKey)} />
      </ImageWrapper>
      <Info gap="8x" alignItems="flex-start">
        <Stack gap="4x">
          <div>
            <Paragraph size="sm" color="subtle">
              {t(`${userRole}.heading`)}
            </Paragraph>
            <Heading>
              {t([`${userRole}.title_${currentBrand}`, `${userRole}.title`], {
                brandName: BRAND_CONFIG.name,
              })}
            </Heading>
          </div>
          <Paragraph>{t(`${userRole}.description`)}</Paragraph>
          {USPS[currentBrand][userRole].map(({ id }) => (
            <ExpandableUsp
              key={id}
              title={t(`${userRole}.usps.${id}.title`)}
              description={t(`${userRole}.usps.${id}.description`)}
            />
          ))}
        </Stack>

        <LinkButtonV2 {...linkProps}>{t(`${userRole}.cta`)}</LinkButtonV2>
      </Info>
    </Wrapper>
  )
}
