import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Paragraph, useBreakpointValue, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import type { StaticImageData } from 'next/image'

import { Link as RouterLink } from '../../vendor/next'
import { Image } from '../../ui-shared/_core/image'
import { currentBackendPlatform } from '../../config'
import { LinkButtonV2 } from '../../ui-shared/_core/link-button-v2'
import { getSearchQueryString } from '../find-home/filters/utils/search.utils'

import { CITY_HOME_COUNT } from './place.gql'

type PlaceCardProps = {
  name: string
  identifier: string
  image: StaticImageData
  searchArea: {
    name: string
    countryCode: string
  }
}

const CARD_HEIGHT = 364
const CARD_HEIGHT_DESKTOP = 500
export const CARD_WIDTH_DESKTOP = 400

type Props = {
  place: PlaceCardProps
}

const CardWrapper = styled.div(({ theme }) => ({
  width: '70vw',
  height: CARD_HEIGHT,
  backgroundColor: theme.colors.bg.default,
  borderRadius: 48,
  position: 'relative',
  margin: `0 ${theme.spacing['3x']}`,

  [theme.mediaQueries.smUp]: {
    width: CARD_WIDTH_DESKTOP,
    height: CARD_HEIGHT_DESKTOP,
    margin: '0 24px',
  },
}))
const StyledImage = styled(Image)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing['6x'],
  right: theme.spacing['6x'],
  width: '65%',
  borderRadius: theme.radii.lg,
}))

const BottomWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  bottom: 0,
  padding: `0px ${theme.spacing['6x']} ${theme.spacing['6x']} ${theme.spacing['6x']}`,
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',

  [theme.mediaQueries.smUp]: {
    paddingLeft: 32,
  },
}))

export function PlacesCard({ place }: Props) {
  const { image, name, searchArea, identifier } = place
  const { t } = useTranslation('popular_places')
  const isMobile = useBreakpointValue({ base: true, sm: false }, { ssr: true })

  const { data: homeCountData } = useQuery(CITY_HOME_COUNT, {
    variables: {
      searchParams: {
        platform: currentBackendPlatform,
        areaIdentifier: [identifier],
      },
    },
  })

  const queryStrings = getSearchQueryString({
    filterValues: { searchAreas: [searchArea] },
  })

  const CardContent = (
    <CardWrapper>
      <StyledImage src={image} />
      <BottomWrapper>
        <Stack>
          <Paragraph>{name}</Paragraph>
          <Paragraph> {t('home', { count: homeCountData?.homeSearch.documents.totalCount })}</Paragraph>
        </Stack>
        {!isMobile && (
          <LinkButtonV2 href={'/find-home' + queryStrings} size="xl">
            {t('see_homes')}
          </LinkButtonV2>
        )}
      </BottomWrapper>
    </CardWrapper>
  )

  if (isMobile) return <RouterLink href={'/find-home' + queryStrings}>{CardContent}</RouterLink>

  return CardContent
}
